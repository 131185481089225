import { GENERIC } from "./actions/utilities";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const storedState = localStorage.getItem("StateId");
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers.StateId = storedState;
      return config;
    },
    (err) => Promise.reject(err)
  );
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // If the response status is 401, it means the user is unauthorized.
      if (response.status === 401) {
        // You can use a state variable to control the visibility of the modal in your application.
        store.dispatch({
          type: GENERIC.SET_SESSION_EXPIRE_STATE,
          payload: true,
        }); // Dispatch an action to show the relogin modal.
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        store.dispatch({
          type: GENERIC.SET_SESSION_EXPIRE_STATE,
          payload: true,
        });
      }
      if (error?.response?.status === 500) {
        return Promise.reject("");
      }
      return Promise.reject(error);
    }
  );
}

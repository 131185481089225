import {
  request,
  success,
  failure,
  DHIS2_DATASET_SETUP_REQUEST,
} from "./utilities";
import {
  addDataSetSetupApi,
  updateDataSetSetupApi,
  deleteDataSetSetupApi,
  getDataSetSetupListApi,
  getDatabaseLabelListApi,
  CheckDHISPushRequestApi,
} from "../api";

export function addDataSetSetupAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_REQUEST));
    addDataSetSetupApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_SUCCESS,
                response.data.data,
                {count:response.data?.totalRecords}
              )
            );
            onSuccessCallback();
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateDataSetSetupAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_REQUEST));
    updateDataSetSetupApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function deleteDataSetSetupAction(data, modalClose, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_REQUEST));
    deleteDataSetSetupApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_SUCCESS,
                data.id
              )
            );
            if (modalClose) {
              modalClose();
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDataSetSetupListAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_REQUEST)
    );
    getDataSetSetupListApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_SUCCESS,
                response.data.data,
                {count:response.data.totalRecords}

              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getDatabaseLabelListAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_REQUEST)
    );
    getDatabaseLabelListApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function CheckDHISPushRequestAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_REQUEST)
    );
    CheckDHISPushRequestApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_SUCCESS,
                response.data.data
              )
            );
            if(onSuccessCallback){
              onSuccessCallback(response.data.message)
            }
          } else {
            onFailure = true;

            dispatch(
              failure(
                DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_FAILURE,
                response.data
              )
            );
            if(onSuccessCallback){
              onSuccessCallback(response.data)
            }
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_FAILURE,
              error.message
            )
          );
          if(onSuccessCallback){
            onSuccessCallback(error)
          }
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import Select from "react-select";
import { PATH } from "../../../../../app/config";
import { ROLE_POLICES, dashboardOption } from "../../../../../app/constant";

import {
  FaClinicMedical,
  FaUserMd,
  FaVideo,
  FaListUl,
  FaHandHoldingHeart,
  FaBaby,
  FaHospitalUser,
  FaTachometerAlt,
  FaUserShield,
} from "react-icons/fa";
import { FcDataConfiguration } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountAction } from "../../../../../redux/actions";
import { HeadsetMicOutlined } from "@material-ui/icons";
import { KEY_PREFIX } from "redux-persist";

export function AsideMenuList({ layoutProps }) {
  const [dashboard, setDashboard] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const counts = useSelector((state) => state.mainDashboard);
  let policesData = useSelector((state) => state?.clinicsReducer);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!counts.allCountRecords.length > 0) {
      dispatch(getAllCountAction());
    }
    //eslint-disable-next-line
  }, []);


  const getCount = (id) => {
    if (id == 9)
      return counts.allCountRecords.totalAdmins || 0
    else if (id == 10)
      return counts.allCountRecords.totalDoctors || 0
    else if (id == 11)
      return counts.allCountRecords.totalCaregivers || 0
    else if (id == 12)
      return counts.allCountRecords.totalPatients || 0
    else if (id == 8)
      return counts.allCountRecords.totalClinics || 0
    else if (id == 13)
      return counts.allCountRecords.totalChilds || 0
    else return -1
  }
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
    }
  };



  const handleDashboard = (current) => {

    let option = [...dashboardOption];

    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard(option[1]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[2]);
    }

    return setDashboard(option[0]);
  };

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // let combineData=ROLE_POLICES.filter((item)=>policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies.some(
  //   (subItem) => subItem.value === item.value
  // ))
  const combinedData = ROLE_POLICES.filter((item) =>
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies.some(
      (subItem) => subItem.value === item.value
    )
  );
  const SidebarItems = () => {
    return (
      <>
        {combinedData?.reverse()?.map((item) => {
          return (
            <>
              <li
                className={`menu-item ${getMenuItemActive(
                  item.path,
                  false
                )} ${getMenuItemActive(item.path, false)} ${getMenuItemActive(
                  item.path,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={item.path}>
                  <span className="svg-icon menu-icon">
                    {item.icon ? item.icon : "N/A"}
                  </span>
                  <span className="menu-text">{item.name}</span>
                  {
                    getCount(item.id) > 0 && (<span className="menu-arrow">
                      {getCount(item.id)}
                    </span>
                    )

                  }
                </NavLink>
              </li>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}
        onClick={() => dispatch({ type: "RESET_LOCATIONS" })}>
        <SidebarItems />
      </ul>
    </>
  );

  
}

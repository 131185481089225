import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Immunization
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addChildObservationApi(data) {
  return axios.post(API_PATH.CHILD_OBSERVATION.addChildObservation, data);
}

/**
 * Get Update Immunization
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateChildObservationApi(data) {
  return axios.put(API_PATH.CHILD_OBSERVATION.updateChildObservation, data);
}
/**
 * Get Recorded Immunization List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getChildObservationApi(data) {
  return axios.get(API_PATH.CHILD_OBSERVATION.getChildObservation, {
    params: data,
  });
}

/**
 * Get Single Observation
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getSingleChildObservationDetailApi(data) {
  return axios.get(API_PATH.CHILD_OBSERVATION.getSingleChildObservation, {
    params: data,
  });
}

import React from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
export const DeleteModal = ({
  title,
  show,
  handleClose,
  body,
  isLoading,
  onSubmit,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Are You Sure?</h4>
        <p className="text-center">{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={onSubmit} disabled={isLoading}>
          {isLoading === true && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
          )}
          Yes
        </Button>
        <Button onClick={handleClose} disabled={isLoading} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addDataelementConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATAELEMENT_CONFIGURATION.addDataelementConfiguration,
    data
  );
}

/**
 * Get Update Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateDataelementConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATAELEMENT_CONFIGURATION.updateDataelementConfiguration,
    data
  );
}

/**
 * Delete Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deleteDataelementConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_DATAELEMENT_CONFIGURATION.deleteDataelementConfiguration,
    data
  );
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDataelementConfigurationApi(data) {
  return axios.get(`${API_PATH.DHIS_DATAELEMENT_CONFIGURATION.getDataelementConfiguration}`, {
    params: data,
  });
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDataElementMappingSelectListApi(data) {
  return axios.get(
    API_PATH.DHIS_DATAELEMENT_CONFIGURATION.getDataElementMappingSelectList,
    {
      params: data,
    }
  );
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Pending Vaccine List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getPendingVaccineApi(data) {
  return axios.get(API_PATH.CHILD_IMMUNIZATION.getPendingVaccine, {
    params: data,
  });
}

/**
 * Get Add Immunization
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addImmunizationApi(data) {
  return axios.post(API_PATH.CHILD_IMMUNIZATION.addImmunization, data);
}

/**
 * Get Update Immunization
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateImmunizationApi(data) {
  return axios.post(API_PATH.CHILD_IMMUNIZATION.updateImmunization, data);
}
/**
 * Get Recorded Immunization List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getRecordedImmunizationsApi(data) {
  return axios.get(API_PATH.CHILD_IMMUNIZATION.getRecordedImmunizations, {
    params: data,
  });
}

/**
 * Get Single Immunization Detail
 *
 * @param {Object} data
 * @return {*} Promise
 */

export function getSingleImmunizationDetailApi(data) {
  return axios.get(
    `${API_PATH.CHILD_IMMUNIZATION.getSingleImmunization}?ImzId=${data}`
  );
}

import { MAIN_DASHBOARD } from "../actions/utilities";

const INITIAL_STATE = {
  getStatsLoading: false,
  getStatsSuccess: false,
  getStatsFailure: false,
  getStatsError: null,
  mainDashboardStats: [],

  getHistoryGraphLoading: false,
  getHistoryGraphSuccess: false,
  getHistoryGraphFailure: false,
  getHistoryGraphError: null,
  historyGraphData: [],

  getCaseProfileLoading: false,
  getCaseProfileSuccess: false,
  getCaseProfileFailure: false,
  getCaseProfileError: null,
  caseProfileData: [],
  totalcaseProfileCount: 0,

  getSmsNudgesListLoading: false,
  getSmsNudgesListSuccess: false,
  getSmsNudgesListFailure: false,
  getSmsNudgesListError: null,
  SmsNudgesListData: [],

  getIvrListLoading: false,
  getIvrListSuccess: false,
  getIvrListFailure: false,
  getIvrListError: null,
  IvrListData: [],

  getNotifyVisitorsListLoading: false,
  getNotifyVisitorsListSuccess: false,
  getNotifyVisitorsListFailure: false,
  getNotifyVisitorsListError: null,
  notifyVisitorsListData: [],

  getAllCountLoading: false,
  getAllCountSuccess: false,
  getAllCountFailure: false,
  getAllCountError: null,
  allCountRecords: [],

  exportHistoryGraphDataLoading: false,
  exportHistoryGraphDataSuccess: false,
  exportHistoryGraphDataFailure: false,
  exportHistoryGraphDataError: null,
  exportHistoryGraphData: null,

  exportStatsDataLoading: false,
  exportStatsDataSuccess: false,
  exportStatsDataFailure: false,
  exportStatsDataError: null,
  exportStatsData: null,

  exportNotifyUserDataLoading: false,
  exportNotifyUserDataSuccess: false,
  exportNotifyUserDataFailure: false,
  exportNotifyUserDataError: null,
  exportNotifyUserData: null,


  exportIVRDataLoading: false,
  exportIVRDataSuccess: false,
  exportIVRDataFailure: false,
  exportIVRDataError: null,
  exportIVRData: null,

  sendNotifiedUserManualMessageLoading: false,
  sendNotifiedUserManualMessageSuccess: false,
  sendNotifiedUserManualMessageFailure: false,
  sendNotifiedUserManualMessageError: null,
};

export const mainDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAIN_DASHBOARD.GET_STATS_REQUEST:
      return {
        ...state,
        getStatsLoading: true,
        getStatsSuccess: false,
        getStatsFailure: false,
        getStatsError: null,
      };
    case MAIN_DASHBOARD.GET_STATS_FAILURE:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: false,
        getStatsFailure: true,
        getStatsError: action.payload,
      };
    case MAIN_DASHBOARD.GET_STATS_SUCCESS:
      return {
        ...state,
        getStatsLoading: false,
        getStatsSuccess: true,
        getStatsFailure: false,
        getStatsError: null,
        mainDashboardStats: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST:
      return {
        ...state,
        getHistoryGraphLoading: true,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: [],
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: false,
        getHistoryGraphFailure: true,
        getHistoryGraphError: action.payload,
      };
    case MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS:
      return {
        ...state,
        getHistoryGraphLoading: false,
        getHistoryGraphSuccess: true,
        getHistoryGraphFailure: false,
        getHistoryGraphError: null,
        historyGraphData: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST:
      return {
        ...state,
        getCaseProfileLoading: true,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: false,
        getCaseProfileFailure: true,
        getCaseProfileError: action.payload,
      };
    case MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS:
      return {
        ...state,
        getCaseProfileLoading: false,
        getCaseProfileSuccess: true,
        getCaseProfileFailure: false,
        getCaseProfileError: null,
        caseProfileData: action.payload,
        totalcaseProfileCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_IVR_LIST_REQUEST:
      return {
        ...state,
        getIvrListLoading: true,
        getIvrListSuccess: false,
        getIvrListFailure: false,
        getIvrListError: null,
        IvrListData: [],
      };
    case MAIN_DASHBOARD.GET_IVR_LIST_FAILURE:
      return {
        ...state,
        getIvrListLoading: false,
        getIvrListSuccess: false,
        getIvrListFailure: true,
        getIvrListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_IVR_LIST_SUCCESS:
      return {
        ...state,
        getIvrListLoading: false,
        getIvrListSuccess: true,
        getIvrListFailure: false,
        getIvrListError: null,
        IvrListData: action.payload,
        totalIVRCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST:
      return {
        ...state,
        getSmsNudgesListLoading: true,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: [],
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: false,
        getSmsNudgesListFailure: true,
        getSmsNudgesListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS:
      return {
        ...state,
        getSmsNudgesListLoading: false,
        getSmsNudgesListSuccess: true,
        getSmsNudgesListFailure: false,
        getSmsNudgesListError: null,
        SmsNudgesListData: action.payload,
        totalSmsNudgesCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_REQUEST:
      return {
        ...state,
        getNotifyVisitorsListLoading: true,
        getNotifyVisitorsListSuccess: false,
        getNotifyVisitorsListFailure: false,
        getNotifyVisitorsListError: null,
        notifyVisitorsListData: [],
      };
    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE:
      return {
        ...state,
        getNotifyVisitorsListLoading: false,
        getNotifyVisitorsListSuccess: false,
        getNotifyVisitorsListFailure: true,
        getNotifyVisitorsListError: action.payload,
      };
    case MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_SUCCESS:
      return {
        ...state,
        getNotifyVisitorsListLoading: false,
        getNotifyVisitorsListSuccess: true,
        getNotifyVisitorsListFailure: false,
        getNotifyVisitorsListError: null,
        notifyVisitorsListData: action.payload,
        totalNotifyVisitorsCount: action.info.count,
      };

    case MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST:
      return {
        ...state,
        getAllCountLoading: true,
        getAllCountSuccess: false,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: [],
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: false,
        getAllCountFailure: true,
        getAllCountError: action.payload,
      };
    case MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS:
      return {
        ...state,
        getAllCountLoading: false,
        getAllCountSuccess: true,
        getAllCountFailure: false,
        getAllCountError: null,
        allCountRecords: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST:
      return {
        ...state,
        exportHistoryGraphDataLoading: true,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: null,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: false,
        exportHistoryGraphDataFailure: true,
        exportHistoryGraphDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        exportHistoryGraphDataLoading: false,
        exportHistoryGraphDataSuccess: true,
        exportHistoryGraphDataFailure: false,
        exportHistoryGraphDataError: null,
        exportHistoryGraphData: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_REQUEST:
      return {
        ...state,
        exportStatsDataLoading: true,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: null,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_FAILURE:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: false,
        exportStatsDataFailure: true,
        exportStatsDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_STATS_DATA_SUCCESS:
      return {
        ...state,
        exportStatsDataLoading: false,
        exportStatsDataSuccess: true,
        exportStatsDataFailure: false,
        exportStatsDataError: null,
        exportStatsData: action.payload,
      };

    case MAIN_DASHBOARD.EXPORT_IVR_DATA_REQUEST:
      return {
        ...state,
        exportIVRDataLoading: true,
        exportIVRDataSuccess: false,
        exportIVRDataFailure: false,
        exportIVRDataError: null,
        exportIVRData: null,
      };
    case MAIN_DASHBOARD.EXPORT_IVR_DATA_FAILURE:
      return {
        ...state,
        exportIVRDataLoading: false,
        exportIVRDataSuccess: false,
        exportIVRDataFailure: true,
        exportIVRDataError: action.payload,
        exportIVRData: null,
      };
    case MAIN_DASHBOARD.EXPORT_IVR_DATA_SUCCESS:
      return {
        ...state,
        exportIVRDataLoading: false,
        exportIVRDataSuccess: true,
        exportIVRDataFailure: false,
        exportIVRDataError: null,
        exportIVRData: action.payload,
      };

    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_REQUEST:
      return {
        ...state,
        exportNotifyUserDataLoading: true,
        exportNotifyUserDataSuccess: false,
        exportNotifyUserDataFailure: false,
        exportNotifyUserDataError: null,
        exportNotifyUserData: null,
      };
    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_FAILURE:
      return {
        ...state,
        exportNotifyUserDataLoading: false,
        exportNotifyUserDataSuccess: false,
        exportNotifyUserDataFailure: true,
        exportNotifyUserDataError: action.payload,
      };
    case MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_SUCCESS:
      return {
        ...state,
        exportNotifyUserDataLoading: false,
        exportNotifyUserDataSuccess: true,
        exportNotifyUserDataFailure: false,
        exportNotifyUserDataError: null,
        exportNotifyUserData: action.payload,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: true,
        sendNotifiedUserManualMessageSuccess: false,
        sendNotifiedUserManualMessageFailure: false,
        sendNotifiedUserManualMessageError: null,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: false,
        sendNotifiedUserManualMessageSuccess: false,
        sendNotifiedUserManualMessageFailure: true,
        sendNotifiedUserManualMessageError: action.payload,
      };
    case MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS:
      return {
        ...state,
        sendNotifiedUserManualMessageLoading: false,
        sendNotifiedUserManualMessageSuccess: true,
        sendNotifiedUserManualMessageFailure: false,
        sendNotifiedUserManualMessageError: null,
        sendManualMessage: action.payload,
      };
    default:
      return state;
  }
};

import { request, success, failure, ANC_DASHBOARD } from "./utilities";
import {
  getAncStatsApi,
  getVaccinationChartApi,
  getCompletedVaccinationApi,
  getIncompletedVaccinationApi,
  getMissedVaccinationApi,
  getMotherVaccinationApi,
  getMotherAncDetailApi,
  exportAncStatsExcelApi,
  exportMissedVaccinationExcelApi,
  exportMotherVaccinationExcelApi,
} from "../api";
import { downloadBase64File } from "../../app/constant";

export function getAncStateAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_ANC_STATS_REQUEST));
    getAncStatsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(ANC_DASHBOARD.GET_ANC_STATS_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(ANC_DASHBOARD.GET_ANC_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(ANC_DASHBOARD.GET_ANC_STATS_FAILURE, error.message));
      }
    );
  };
}

export function getVaccinationChartAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_VACCINATION_CHART_REQUEST));
    getVaccinationChartApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_VACCINATION_CHART_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_VACCINATION_CHART_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ANC_DASHBOARD.GET_VACCINATION_CHART_FAILURE, error.message)
        );
      }
    );
  };
}

export function getCompletedVaccinationAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_COMPLETED_VACCINATION_REQUEST));
    getCompletedVaccinationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_COMPLETED_VACCINATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_COMPLETED_VACCINATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            ANC_DASHBOARD.GET_COMPLETED_VACCINATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getIncompletedVaccinationAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_REQUEST));
    getIncompletedVaccinationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getMissedVaccinationAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_MISSED_VACCINATION_REQUEST));
    getMissedVaccinationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_MISSED_VACCINATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_MISSED_VACCINATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ANC_DASHBOARD.GET_MISSED_VACCINATION_FAILURE, error.message)
        );
      }
    );
  };
}

export function getMotherVaccinationAction(filters) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_MOTHER_VACCINATION_REQUEST));
    getMotherVaccinationApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_MOTHER_VACCINATION_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_MOTHER_VACCINATION_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ANC_DASHBOARD.GET_MOTHER_VACCINATION_FAILURE, error.message)
        );
      }
    );
  };
}

export function getMotherAncDetailAction(data) {
  return (dispatch) => {
    dispatch(request(ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_REQUEST));
    getMotherAncDetailApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_FAILURE, error.message)
        );
      }
    );
  };
}

export function exportAncStatsExcelAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_REQUEST));
    exportAncStatsExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_SUCCESS,
                response.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_FAILURE, error.message)
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportMissedVaccinationAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_REQUEST));
    exportMissedVaccinationExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportMotherVaccinationAction(
  filters,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;
    dispatch(request(ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_REQUEST));
    exportMotherVaccinationExcelApi(filters)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          } else {
            onFailure = true;

            dispatch(
              failure(
                ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

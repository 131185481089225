import React from "react";
import { Container } from "react-bootstrap";

export default function TermsandCondtitions() {
  return (
    <Container>
      <h1 className="text-center mt-5">Privacy Policy</h1>
      <ul>
        <li><h5>Introduction</h5></li>
        <p className="text-justify">OGOW Health, OGOW Corp., OGOW EMR, and our affiliates and subsidiaries (”Company” or “We”) respect your privacy and are committed to protecting it by complying with this policy.</p>
        <p>This policy describes:</p>
        <ul>
          <li>The types of information we may collect or that app users (”you”) may provide when you install, register with, access, or use the mobile applications, OGOW Health or OGOW EMR (combined, the “App”) and our Dashboard.</li>
          <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
        </ul>
        <p className="text-justify">We will only use your personal information in accordance with this policy unless otherwise required by applicable law. We take steps to ensure that the personal information that we collect about you is adequate, relevant, not excessive, and used for limited purposes.</p>
        <p className="text-justify">Please read this policy carefully to understand our policies and practices for collecting, processing, and storing your information. If you do not agree with our policies and practices, do not install, register with, or use the App. By downloading, registering with, or using the App, you indicate that you understand, accept, and consent to the practices described in this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of the App after we make changes indicates that you accept and consent to those changes, so please check the policy periodically for updates. </p>
        <li><h5>Information We Collect and How We Collect It</h5></li>
        <p>We collect information through:</p>
        <ul>
          <li>Direct interactions with you when you provide it to us, for example, by filling in forms or corresponding with us; and</li>
          <li>Automated technologies or interactions when you use the App, for example, usage details, IP addresses, and information collected through cookies and other tracking technologies.</li>
        </ul>
        <p>The types of information that we collect include:</p>
        <ul>
          <li>Personal information that we can reasonably use to directly or indirectly identify an individual, such as name, mailing address, email address, telephone number,, user name or other similar identifier, any other identifier used to contact an individual, and Personal Health Information as defined below (”Personal Information”).</li>
          <li>Non-personal information that does not directly or indirectly reveal your identity or directly relate to an identified individual, such as demographic information, statistical or aggregated information. We may derive non-personal statistical or aggregated data from personal information. For example, we may aggregate personal information to calculate the percentage of users accessing a specific app feature.</li>
        <li>Technical information, including your login information, device type, time zone setting, and usage details.</li>
        </ul>
        <li><h5>Protected Health Information</h5></li>
        <p>For purposes of this policy, Protected Health Information (“PHI”) is defined as any stored information containing at least one of the following regarding a patient::</p>
        <ul>
          <li>name(s);</li>
          <li>key dates (including birth, registration, visit, death);</li>
          <li>age(s);</li>
          <li>patient and/or their family phone/fax numbers, e-mail addresses, and/or social media account names;</li>
          <li>references to a patient-related geographical subdivision (if smaller than 20,000 people);</li>
          <li>social security, tax identification, or other patient account numbers;</li>
          <li>patient-related device identifiers, addresses, or serial numbers (including IP addresses, MAC addresses, computer/-device serial numbers, beneficiary numbers, account numbers, and personally-identifying URLs);</li>
          <li>license information (including drivers’ license and license plate numbers, and professional membership licensing numbers);</li>
          <li>images containing the face of a patient, patient’s family member, or any other patient-related contact;</li>
          <li>any biometric data describing a patient (including eye/hand measurements, height, weight, or clothing sizes); and</li>
          <li>any unique identifier or code, other than a study-specific unique identifier assigned for purposes of managing an approved research protocol.</li>
        </ul>
        <li><h5>Information You Provide to Us</h5></li>
        <p>When you download, register with, or use the App, we may ask you to provide:</p>
        <ul>
          <li>Information by filling in forms in the App. This includes information you provide when registering to use the App, posting material, and requesting further services. We may also ask you for information when you report a problem with the App.</li>
          <li>A profile photo for your doctor profile (if applicable), to be stored on our server and only associated with the profile.</li>
          <li>Personal Health Information of your patients.</li>
          <li>Records and copies of your correspondence, including email addresses and phone numbers, if you contact us.</li>
          <li>Your responses to surveys that we might ask you to complete for research or other purposes.</li>
          <li>Your search queries on the App.</li>
        </ul>
        <p>We may maintain certain Personal Information about you as long as is reasonably necessary for us to complete our dealings with you, or as may be required by law. We also collect educational, training and license information from clinicians and healthcare professionals to authenticate the background of service providers as much as possible.</p>
        <p>In order for medical professionals to give medical opinions regarding health, you may be required to provide us with past and current medical records, description of symptoms, a pictorial representation of the issue, a complete medical history and life-style descriptions. Once your case is submitted and closed, we endeavour to de-link /render anonymous Personal Information like name, phone, email etc., from your medical records on a monthly basis.</p>
        <li><h5>Automatic Information Collection and Tracking Technologies</h5></li>
        <p>When you download, access, and use the App, it may automatically collect:</p>
        <ul>
          <li>Usage details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including communication data and the resources that you access and use on or through the App.</li>
          <li>Device information. We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</li>
        </ul>
        <li><h5>How We Use Your Information</h5></li>
        <p>We use information that we collect about you or that you provide to us, including any personal information:</p>
        <ul>
          <li>To provide you with the App and its contents, including EMR services;</li>
          <li>To provide you with information, products or services that you request from us;</li>
          <li>To fulfill the purposes for which you provided it or that were described when it was collected or any other purpose for which you provide it;</li>
          <li>To carry out our obligations and enforce our rights in any contracts with you, including for billing and collection or to comply with legal requirements;</li>
          <li>To notify you when App updates are available, and of changes to any products or services we offer or provide though it;</li>
          <li>To improve our App, products or services, or customer relationships and experiences; and</li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p className="text-justify">Usage information that we collect helps us to improve the App and to deliver a better and more personalized experience, including by enabling us to:</p>
        <ul>
          <li>Estimate our audience size and usage patterns;</li>
          <li>Store information about your preferences, allowing us to customize our App according to your individual interests;</li>
          <li>Speed up your searches;</li>
          <li>Recognize you when you use the App;</li>
          <li>Gather broad demographic information;</li>
          <li>Improve our marketing and promotional efforts; and</li>
          <li>Help diagnose problems with our server.</li>
        </ul>
        <li><h5>Disclosure of Your Information</h5></li>
        <p className="text-justify">We may share information as discussed below, but will never sell it to advertisers or other third parties. The Company may share information to the following:</p>
        <ul>
          <li>To our affiliates, contractors, service providers, and other third parties we use to support our business, such as analytics and search engine providers that help us optimize and improve our services. We contractually require these third parties to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process personal information following the same standards set out in this policy.</li>
          <li>To fulfill the purpose for which you provide it. </li>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request, according to applicable law.</li>
        </ul>
        <p className="text-justify">In addition, we may generate statistics out of the collected data, which we may then share with government agencies to assist them in making regulatory interventions on health. The statistics shared shall not include specific personal data. </p>
        <li><h5>Data Security</h5></li>
        <p className="text-justify">The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. We store all information you provide to us behind firewalls on our secure servers. </p>
        <p className="text-justify">The safety and security of your information also depends on you. Where we have given you (or you have chosen) a password for access to certain parts of our App, you are responsible for keeping it confidential. We ask you not to share your password with anyone. All access to Personal Health Information must be password protected. </p>
        <p className="text-justify">When communicating information electronically, any and all personal information must be protected using a secure transfer system. Each person is responsible for his/her own compliance. If any person becomes aware of a violation of this policy, they have a duty to report the incident directly to the OGOW compliance department.</p>
        <p className="text-justify">Unfortunately, the transmission of information via the Internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through the App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any App privacy settings or security measures.</p>
        <p className="text-justify">In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable jurisdictional laws and regulations. </p>
        <li><h5>Accessing and Correcting Your Personal Information and Withdrawing Your Consent</h5></li>
        <p className="text-justify">It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law you have the right to request access to and to correct the personal information that we hold about you.</p>
        <p className="text-justify">If you want to review, verify, correct, or withdraw consent to the use of your personal information you may also send us an email at compliance@ogowhealth.com to request access to, correct, or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. </p>
        <p className="text-justify"> We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</p>
        <p className="text-justify">We will provide access to your personal information, subject to exceptions set out in applicable privacy legislation. If you are concerned about our response or would like to correct the information provided, you may contact our Compliance Officer at <a>compliance@ogowhealth.com</a>. </p>
        <li><h5>Changes to Our Privacy Policy</h5></li>
        <p className="text-justify" >We may update our privacy policy from time to time. It is our policy to post any changes we make to our privacy policy on this page with a notice that we have updated the privacy policy.</p>
        <p className="text-justify">We include the date the privacy policy was last revised at the bottom of the page. You are responsible for periodically visiting this privacy policy to check for any changes.</p>
        <li><h5>Contact Information and Challenging Compliance</h5></li>
        <p>We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact us at: <a>info@ogowhealth.com</a>    </p>
        <p className="text-justify">We have procedures in place to receive and respond to complaints or inquiries about our handling of personal information and our compliance with this policy and with applicable privacy laws. To discuss our compliance with this policy please contact our Privacy Officer using the contact information listed above.</p>
        <br/>
        <br/>
        <br/>
        <p>Last updated on: August 30, 2022 </p>
      </ul>
    </Container>
  );
}

import { request, success, failure, GENERIC } from "./utilities";
import {
  activateUserApi,
  deactivateUserApi,
  getUsersSelectListExceptPatientApi,
} from "../api";

export function activateUserAction(data) {
  return (dispatch) => {
    dispatch(request(GENERIC.ACTIVATE_USER_REQUEST));
    activateUserApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(success(GENERIC.ACTIVATE_USER_SUCCESS, response.data.data));
        } else {
          dispatch(
            failure(GENERIC.ACTIVATE_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(GENERIC.ACTIVATE_USER_FAILURE, error.message));
      }
    );
  };
}

export function deactivateUserAction(data) {
  return (dispatch) => {
    dispatch(request(GENERIC.DEACTIVATE_USER_REQUEST));
    deactivateUserApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(GENERIC.DEACTIVATE_USER_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(GENERIC.DEACTIVATE_USER_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(GENERIC.DEACTIVATE_USER_FAILURE, error.message));
      }
    );
  };
}

export function getUsersSelectListExceptPatient(clinicId) {
  return (dispatch) => {
    dispatch(request(GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_REQUEST));
    getUsersSelectListExceptPatientApi(clinicId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(GENERIC.GET_USERS_LIST_EXCEPT_PATIENT_FAILURE, error.message)
        );
      }
    );
  };
}

import { CHILD_PRESCRIPTION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addChildPrescriptionLoading: false,
  addChildPrescriptionSuccess: false,
  addChildPrescriptionFailure: false,
  addChildPrescriptionError: null,
  addChildPrescription: {},

  updateChildPrescriptionLoading: false,
  updateChildPrescriptionSuccess: false,
  updateChildPrescriptionFailure: false,
  updateChildPrescriptionError: null,
  updateChildPrescription: {},

  getChildPrescriptionLoading: false,
  getChildPrescriptionSuccess: false,
  getChildPrescriptionFailure: false,
  getChildPrescriptionError: null,
  childPrescription: [],

  getSingleChildPrescriptionDetailLoading: false,
  getSingleChildPrescriptionDetailSuccess: false,
  getSingleChildPrescriptionDetailFailure: false,
  getSingleChildPrescriptionDetailError: null,
  singleChildPrescriptionDetail: [],
};

export const childPrescriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_REQUEST:
      return {
        ...state,
        addChildPrescriptionLoading: true,
        addChildPrescriptionSuccess: false,
        addChildPrescriptionFailure: false,
        addChildPrescriptionError: null,
        addChildPrescription: {},
      };
    case CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_FAILURE:
      return {
        ...state,
        addChildPrescriptionLoading: false,
        addChildPrescriptionSuccess: false,
        addChildPrescriptionFailure: true,
        addChildPrescriptionError: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.ADD_CHILD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        addChildPrescriptionLoading: false,
        addChildPrescriptionSuccess: true,
        addChildPrescriptionFailure: false,
        addChildPrescriptionError: null,
        addChildPrescription: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_REQUEST:
      return {
        ...state,
        updateChildPrescriptionLoading: true,
        updateChildPrescriptionSuccess: false,
        updateChildPrescriptionFailure: false,
        updateChildPrescriptionError: null,
        updateChildPrescription: {},
      };
    case CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_FAILURE:
      return {
        ...state,
        updateChildPrescriptionLoading: false,
        updateChildPrescriptionSuccess: false,
        updateChildPrescriptionFailure: true,
        updateChildPrescriptionError: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.UPDATE_CHILD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        updateChildPrescriptionLoading: false,
        updateChildPrescriptionSuccess: true,
        updateChildPrescriptionFailure: false,
        updateChildPrescriptionError: null,
        updateChildPrescription: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_REQUEST:
      return {
        ...state,
        getChildPrescriptionLoading: true,
        getChildPrescriptionSuccess: false,
        getChildPrescriptionFailure: false,
        getChildPrescriptionError: null,
        childPrescription: [],
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_FAILURE:
      return {
        ...state,
        getChildPrescriptionLoading: false,
        getChildPrescriptionSuccess: false,
        getChildPrescriptionFailure: true,
        getChildPrescriptionError: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_CHILD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        getChildPrescriptionLoading: false,
        getChildPrescriptionSuccess: true,
        getChildPrescriptionFailure: false,
        getChildPrescriptionError: null,
        childPrescription: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_REQUEST:
      return {
        ...state,
        getSingleChildPrescriptionDetailLoading: true,
        getSingleChildPrescriptionDetailSuccess: false,
        getSingleChildPrescriptionDetailFailure: false,
        getSingleChildPrescriptionDetailError: null,
        singleChildPrescriptionDetail: [],
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_FAILURE:
      return {
        ...state,
        getSingleChildPrescriptionDetailLoading: false,
        getSingleChildPrescriptionDetailSuccess: false,
        getSingleChildPrescriptionDetailFailure: true,
        getSingleChildPrescriptionDetailError: action.payload,
      };
    case CHILD_PRESCRIPTION_REQUEST.GET_SINGLE_CHILD_PRESCRIPTION_DETAIL_SUCCESS:
      return {
        ...state,
        getSingleChildPrescriptionDetailLoading: false,
        getSingleChildPrescriptionDetailSuccess: true,
        getSingleChildPrescriptionDetailFailure: false,
        getSingleChildPrescriptionDetailError: null,
        singleChildPrescriptionDetail: action.payload,
      };
    default:
      return state;
  }
};

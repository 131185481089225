import axios from "axios";
import { API_PATH } from "../config";

/**
 * Activate User Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function activateUserApi(data) {
  return axios.post(API_PATH.GENERIC.activateUser, data);
}

/**
 * Deactivate User Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deactivateUserApi(data) {
  return axios.post(API_PATH.GENERIC.deactivateUser, data);
}

/**
 * Get User Select List Except Patient Api
 *
 * @return {*} Promise
 */
export function getUsersSelectListExceptPatientApi(clinicId) {
  return axios.get(API_PATH.GENERIC.getAllUsersExceptPatient, {
    params: clinicId,
  });
}

import { DHIS2_DATASET_CONFIGURATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addDatasetConfigurationLoading: false,
  addDatasetConfigurationSuccess: false,
  addDatasetConfigurationFailure: false,
  addDatasetConfigurationError: null,
  addDatasetConfiguration: {},

  updateDatasetConfigurationLoading: false,
  updateDatasetConfigurationSuccess: false,
  updateDatasetConfigurationFailure: false,
  updateDatasetConfigurationError: null,
  updateDatasetConfiguration: {},

  deleteDatasetConfigurationLoading: false,
  deleteDatasetConfigurationSuccess: false,
  deleteDatasetConfigurationFailure: false,
  deleteDatasetConfigurationError: null,
  deleteDatasetConfiguration: {},

  getDatasetConfigurationLoading: false,
  getDatasetConfigurationSuccess: false,
  getDatasetConfigurationFailure: false,
  getDatasetConfigurationError: null,
  getDatasetConfiguration: [],

  getDatasetMappingSelectListLoading: false,
  getDatasetMappingSelectListSuccess: false,
  getDatasetMappingSelectListFailure: false,
  getDatasetMappingSelectListError: null,
  datasetMappingSelectList: [],
};

export const dhisDataSetConfigurationReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_REQUEST:
      return {
        ...state,
        addDatasetConfigurationLoading: true,
        addDatasetConfigurationSuccess: false,
        addDatasetConfigurationFailure: false,
        addDatasetConfigurationError: null,
        addDatasetConfiguration: {},
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_FAILURE:
      return {
        ...state,
        addDatasetConfigurationLoading: false,
        addDatasetConfigurationSuccess: false,
        addDatasetConfigurationFailure: true,
        addDatasetConfigurationError: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.ADD_DATASET_CONFIGURATION_SUCCESS:
      let updatedDatasetConfiguation = [
        ...state.getDatasetConfiguration,
        ...action.payload.data,
      ];
      return {
        ...state,
        addDatasetConfigurationLoading: false,
        addDatasetConfigurationSuccess: true,
        addDatasetConfigurationFailure: false,
        addDatasetConfigurationError: null,
        addDatasetConfiguration: action.payload,
        getDatasetConfiguration: updatedDatasetConfiguation,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_REQUEST:
      return {
        ...state,
        updateDatasetConfigurationLoading: true,
        updateDatasetConfigurationSuccess: false,
        updateDatasetConfigurationFailure: false,
        updateDatasetConfigurationError: null,
        updateDatasetConfiguration: {},
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_FAILURE:
      return {
        ...state,
        updateDatasetConfigurationLoading: false,
        updateDatasetConfigurationSuccess: false,
        updateDatasetConfigurationFailure: true,
        updateDatasetConfigurationError: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.UPDATE_DATASET_CONFIGURATION_SUCCESS:
      let updatedUpdateDatasetConfiguation = [...state.getDatasetConfiguration];
      let findIndex = updatedUpdateDatasetConfiguation.findIndex(
        (item) => item.id === action.payload.id
      );
      updatedUpdateDatasetConfiguation[findIndex] = action.payload;
      return {
        ...state,
        updateDatasetConfigurationLoading: false,
        updateDatasetConfigurationSuccess: true,
        updateDatasetConfigurationFailure: false,
        updateDatasetConfigurationError: null,
        updateDatasetConfiguration: action.payload,
        getDatasetConfiguration: updatedUpdateDatasetConfiguation,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_REQUEST:
      return {
        ...state,
        deleteDatasetConfigurationLoading: true,
        deleteDatasetConfigurationSuccess: false,
        deleteDatasetConfigurationFailure: false,
        deleteDatasetConfigurationError: null,
        deleteDatasetConfiguration: {},
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_FAILURE:
      return {
        ...state,
        deleteDatasetConfigurationLoading: false,
        deleteDatasetConfigurationSuccess: false,
        deleteDatasetConfigurationFailure: true,
        deleteDatasetConfigurationError: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.DELETE_DATASET_CONFIGURATION_SUCCESS:
      let deletePermanentDatasetConfiguration = state.getDatasetConfiguration.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        deleteDatasetConfigurationLoading: false,
        deleteDatasetConfigurationSuccess: true,
        deleteDatasetConfigurationFailure: false,
        deleteDatasetConfigurationError: null,
        deleteDatasetConfiguration: action.payload,
        getDatasetConfiguration: deletePermanentDatasetConfiguration,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_REQUEST:
      return {
        ...state,
        getDatasetConfigurationLoading: true,
        getDatasetConfigurationSuccess: false,
        getDatasetConfigurationFailure: false,
        getDatasetConfigurationError: null,
        getDatasetConfiguration: [],
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_FAILURE:
      return {
        ...state,
        getDatasetConfigurationLoading: false,
        getDatasetConfigurationSuccess: false,
        getDatasetConfigurationFailure: true,
        getDatasetConfigurationError: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        getDatasetConfigurationLoading: false,
        getDatasetConfigurationSuccess: true,
        getDatasetConfigurationFailure: false,
        getDatasetConfigurationError: null,
        getDatasetConfiguration: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_REQUEST:
      return {
        ...state,
        getDatasetMappingSelectListLoading: true,
        getDatasetMappingSelectListSuccess: false,
        getDatasetMappingSelectListFailure: false,
        getDatasetMappingSelectListError: null,
        datasetMappingSelectList: [],
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_FAILURE:
      return {
        ...state,
        getDatasetMappingSelectListLoading: false,
        getDatasetMappingSelectListSuccess: false,
        getDatasetMappingSelectListFailure: true,
        getDatasetMappingSelectListError: action.payload,
      };
    case DHIS2_DATASET_CONFIGURATION_REQUEST.GET_DATASET_MAPPING_SELECT_LIST_SUCCESS:
      return {
        ...state,
        getDatasetMappingSelectListLoading: false,
        getDatasetMappingSelectListSuccess: true,
        getDatasetMappingSelectListFailure: false,
        getDatasetMappingSelectListError: null,
        datasetMappingSelectList: action.payload,
      };
    default:
      return state;
  }
};

import { request, success, failure, MAIN_DASHBOARD } from "./utilities";
import {
  getStatsApi,
  getHistoryGraphApi,
  getCaseProfileTableApi,
  getSmsNudgesListApi,
  exportHistoryGraphDataApi,
  getAllCountApi,
  getNotifyVisitorsListApi,
  exportNotifyUserDataApi,
  sendNotifiedUserManualMessageApi,
  getIVRListingApi,
  exportIVRDataApi,
} from "../api";
import { downloadBase64File } from "../../app/constant";

export function getStateAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_STATS_REQUEST));
    getStatsApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_STATS_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_STATS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_STATS_FAILURE, error.message));
      }
    );
  };
}

export function getHistoryGraph(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_HISTORY_GRAPH_REQUEST));
    getHistoryGraphApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_HISTORY_GRAPH_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getCaseProfileAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_REQUEST));
    getCaseProfileTableApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(MAIN_DASHBOARD.GET_CASE_PROFILE_TABLE_FAILURE, error.message)
        );
      }
    );
  };
}

export function getAllCountAction(filters) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_ALL_COUNT_REQUEST));
    getAllCountApi().then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_ALL_COUNT_SUCCESS, response.data.data)
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(MAIN_DASHBOARD.GET_ALL_COUNT_FAILURE, error.message));
      }
    );
  };
}

export function getIVRListingList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_IVR_LIST_REQUEST));
    getIVRListingApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(MAIN_DASHBOARD.GET_IVR_LIST_SUCCESS, response.data.data, {
              count: response.data.totalRecords,
            })
          );
        } else {
          dispatch(
            failure(MAIN_DASHBOARD.GET_IVR_LIST_FAILURE, response.data.message)
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_IVR_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}
export function getSmsNudgesList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_REQUEST));
    getSmsNudgesListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
              response.data.message
            )
          );
          // Notificiation(response.data.message, "error")
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_SMS_NUDGES_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function getNotifyVisitorsList(filterData) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_REQUEST));
    getNotifyVisitorsListApi(filterData).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_SUCCESS,
              response.data.data,
              { count: response.data.totalRecords }
            )
          );
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.GET_NOTIFY_VISITORS_LIST_FAILURE,
            error &&
              error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message
          )
        );
      }
    );
  };
}

export function exportHistoryGraphData(
  filterData,
  fileName,
  onSuccessCallback
) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_REQUEST));
    exportHistoryGraphDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_HISTORY_GRAPH_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function exportNotifyUserData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_REQUEST));
    exportNotifyUserDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_NOTIFY_USER_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}


export function exportIVRData(filterData, fileName, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(MAIN_DASHBOARD.EXPORT_IVR_DATA_REQUEST));
    exportIVRDataApi(filterData)
      .then(
        (response) => {
          if (response.data !== undefined) {
            onSuccess = true;

            dispatch(
              success(
                MAIN_DASHBOARD.EXPORT_IVR_DATA_SUCCESS,
                response.data.data
              )
            );

            downloadBase64File(response.data, fileName);
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              MAIN_DASHBOARD.EXPORT_IVR_DATA_FAILURE,
              error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}
export function sendNotifiedUserManualMessageActions(data, moveToNext) {
  return (dispatch) => {
    dispatch(request(MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_REQUEST));
    sendNotifiedUserManualMessageApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_SUCCESS,
              response.data
            )
          );
          moveToNext();
        } else {
          dispatch(
            failure(
              MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            MAIN_DASHBOARD.SEND_NOTIFIED_USER_MANUAL_MESSAGE_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get Add Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addFacilityConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.addFacilityConfiguration,
    data
  );
}

export function addDHISFacilityApi(data) {
  return axios.post(API_PATH.DHIS_FACILITY_CONFIGURATION.addDHISFacility, data);
}

export function getDHISFacilitiesListingApi(data) {
  return axios.get(
    `${API_PATH.DHIS_FACILITY_CONFIGURATION.getDHISFacilitiesListing}?DHISMappingStatus=${
      data.DHISMappingStatus
    //3
    }&StateId=
    ${
      //3
      data.StateId
  //  !==undefined?data.StateId:3
  }`
  );
}

export function deleteDHISFacilityApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.deleteDHISFacility,
    data
  );
}
export function updateDHISFacilityApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.updateDHISFacility,
    data
  );
}
export function getByIdDHISFacilityApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.getByIdDHISFacility,
    data
  );
}

/**
 * Get Update Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function updateFacilityConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.updateFacilityConfiguration,
    data
  );
}

/**
 * Delete Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deleteFacilityConfigurationApi(data) {
  return axios.post(
    API_PATH.DHIS_FACILITY_CONFIGURATION.deleteFacilityConfiguration,
    data
  );
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getFacilityConfigurationMappingListApi(data) {
  return axios.get(
    API_PATH.DHIS_FACILITY_CONFIGURATION.getFacilityConfigurationMappingList,
    {
      params: data,
    }
  );
}

import {
  request,
  success,
  failure,
  CHILD_IMMUNIZATION_REQUEST,
} from "./utilities";
import {
  getPendingVaccineApi,
  addImmunizationApi,
  updateImmunizationApi,
  getRecordedImmunizationsApi,
  getSingleImmunizationDetailApi,
} from "../api";

export function getPendingVaccineAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(
      request(CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_REQUEST)
    );
    getPendingVaccineApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_IMMUNIZATION_REQUEST.GET_PENDING_VACCINE_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function addImmunizationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_REQUEST));
    addImmunizationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_IMMUNIZATION_REQUEST.ADD_IMMUNIZATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function updateImmunizationAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_REQUEST));
    updateImmunizationApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;
            dispatch(
              success(
                CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_IMMUNIZATION_REQUEST.UPDATE_IMMUNIZATION_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getRecordedImmunizationsAction(data, onSuccessCallback) {
  return (dispatch) => {
    let onSuccess;
    let onFailure;

    dispatch(request(CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_REQUEST));
    getRecordedImmunizationsApi(data)
      .then(
        (response) => {
          if (response.data.success) {
            onSuccess = true;

            dispatch(
              success(
                CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_SUCCESS,
                response.data.data
              )
            );
          } else {
            onFailure = true;

            dispatch(
              failure(
                CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_FAILURE,
                response.data.message
              )
            );
          }
        },
        (error) => {
          onFailure = true;

          dispatch(
            failure(
              CHILD_IMMUNIZATION_REQUEST.GET_IMMUNIZATION_LIST_FAILURE,
              error.message
            )
          );
        }
      )
      .finally(() => {
        if (onSuccess && onSuccessCallback) {
          onSuccessCallback();
        } else if (onFailure) {
          return;
        }
      });
  };
}

export function getSingleImmunizationDetailAction(data, showModal) {
  return (dispatch) => {
    dispatch(
      request(
        CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_REQUEST
      )
    );

    getSingleImmunizationDetailApi(data).then(
      (response) => {
        if (response.data.success) {
          dispatch(
            success(
              CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_SUCCESS,
              response.data.data
            )
          );
          if (showModal) {
            showModal();
          }
        } else {
          dispatch(
            failure(
              CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            CHILD_IMMUNIZATION_REQUEST.GET_SINGLE_IMMUNIZATION_DATAILS_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

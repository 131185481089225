import axios from "axios";
import { API_PATH } from "../config";

/**
 * Delete Dataset Element
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDataUploadApi(data) {
  return axios.get(API_PATH.DHIS_DATA_UPLOAD.getDhisDataUpload, {
    params: data,
  });
}

/**
 * Get Dataset List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function pushDataUploadApi(data) {
  return axios.post(API_PATH.DHIS_DATA_UPLOAD.pushDhisDataUpload, data);
}

/**
 * get Login Detail
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getDhisLoginApi(data) {
  return axios.get(API_PATH.DHIS_DATA_UPLOAD.dhisLogin, {
    params: data,
  });
}
/**
 * get push requests
 * @param {object} data 
 * @returns {*} Promise
 */
export function getDhisPushRequestsApi(data) {
  return axios.get(API_PATH.DHIS_DATA_UPLOAD.getDhisPushRequests, {
    params: data,
  });
}
/**
 * 
 * @param {object} data 
 * @returns {*} Promise
 */
export function genrateDhisPushRequestsApi(data) {
  return axios.post(API_PATH.DHIS_DATA_UPLOAD.genrateDhisPushRequests, data);
}
/**
 * get dhis logs
 * @param {object} data 
 * @returns {*} Promise
 */
export function getDhisLogsApi(data) {
  return axios.get(API_PATH.DHIS_DATA_UPLOAD.getDhisLogs, {
    params: data,
  });
}

import axios from "axios";
import { API_PATH } from "../config";

/**
 * Get All Videos List
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function getAllVideosApi(data) {
  return axios.get(API_PATH.LEARNING_CENTRE.getVideos, {
    params: data,
  });
}

/**
 * Add Video Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function addVideoApi(data) {
  return axios.post(API_PATH.LEARNING_CENTRE.addVideo, data);
}

/**
 * Delete Video Api
 *
 * @param {Object} data
 * @return {*} Promise
 */
export function deleteVideoApi(data) {
  return axios.post(API_PATH.LEARNING_CENTRE.deleteVideo, data);
}

/** Get Language List */
export function getLanguageListApi() {
  return axios.get(API_PATH.LEARNING_CENTRE.getLanguageList);
}

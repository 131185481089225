import { request, success, failure, PATIENT_REQUEST } from "./utilities";
import {
  getPatientListApi,
  getSinglePatientDetailApi,
  getMotherWithAncDataApi,
  changePatientGenderAPI,
  isReminderEnabledApi,
} from "../api";

export function getPatientList(filters) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.GET_PATIENT_LIST_REQUEST));
    getPatientListApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PATIENT_REQUEST.GET_PATIENT_LIST_SUCCESS,
              response.data.data.patients,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              PATIENT_REQUEST.GET_PATIENT_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(PATIENT_REQUEST.GET_PATIENT_LIST_FAILURE, error.message)
        );
      }
    );
  };
}
export function isReminderEnabledAction(patientId,OnSuccess) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.IS_REMINDER_ENABLED_REQUEST));
    isReminderEnabledApi(patientId).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PATIENT_REQUEST.IS_REMINDER_ENABLED_SUCCESS,
              response.data.data.patients,
              {
                count: response.data.totalRecords,
              }
            )
          );
          if(OnSuccess){
            OnSuccess()
          }
        } else {
          dispatch(
            failure(
              PATIENT_REQUEST.IS_REMINDER_ENABLED_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(PATIENT_REQUEST.IS_REMINDER_ENABLED_FAILURE, error.message)
        );
      }
    );
  };
}
export function getMotherWithAncDataAction(filters) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_REQUEST));
    getMotherWithAncDataApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_SUCCESS,
              response.data.data,
              {
                count: response.data.totalRecords,
              }
            )
          );
        } else {
          dispatch(
            failure(
              PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PATIENT_REQUEST.GET_MOTHERS_WITH_ANC_DATA_FAILURE,
            error.message
          )
        );
      }
    );
  };
}
export function getSinglePatientDetail(filters) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_REQUEST));
    getSinglePatientDetailApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_SUCCESS,
              response.data.data
            )
          );
        } else {
          dispatch(
            failure(
              PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PATIENT_REQUEST.GET_SINGLE_PATIENT_DETAIL_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

export function getPatientChildsList(filters) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_REQUEST));
    getSinglePatientDetailApi(filters).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(
              PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_SUCCESS,
              response.data.data.childs
            )
          );
        } else {
          dispatch(
            failure(
              PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PATIENT_REQUEST.GET_PATIENT_CHILDS_LIST_FAILURE,
            error.message
          )
        );
      }
    );
  };
}


export function changePatientGenderAction(filters,alertFuncOnSuccess,alertFuncOnFailure) {
  return (dispatch) => {
    dispatch(request(PATIENT_REQUEST.CHANGE_PATIENT_GENDER_REQUEST));
    changePatientGenderAPI(filters).then(
      (response) => {
        if (response.data.statusCode === 200) {
          if(alertFuncOnSuccess)alertFuncOnSuccess(response.data.message)
          dispatch(
            success(
              PATIENT_REQUEST.CHANGE_PATIENT_GENDER_SUCCESS,
              response.data.message,
            )
          );
        } else {
          if(alertFuncOnFailure)alertFuncOnFailure(response.data.message)
          dispatch(
            failure(
              PATIENT_REQUEST.CHANGE_PATIENT_GENDER_FAILURE,
              response.data.message
            )
          );
        }
      },
      (error) => {
        dispatch(
          failure(
            PATIENT_REQUEST.CHANGE_PATIENT_GENDER_FAILURE,
            error.message
          )
        );
      }
    );
  };
}

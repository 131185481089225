import * as React from "react";
const Dashboard = React.lazy(() => import("./MainDashboard"));
const ImmunizationDashboard = React.lazy(() =>
  import("./ImmunizationDashboard")
);
const AncDashboard = React.lazy(() => import("./AncDashboard"));
const DHIS2Configuration = React.lazy(() =>
  import("./DhisConfiguration/Pages/DHIS2configuration")
);
const AncSevices = React.lazy(() => import("./AncDashboard/Data/AncSevices"));

const ClinicList = React.lazy(() => import("./ClinicList"));
const ClinicAdd = React.lazy(() => import("./ClinicList/Pages/Add"));
const ClinicEdit = React.lazy(() => import("./ClinicList/Pages/Edit"));
const ClinicView = React.lazy(() => import("./ClinicList/Pages/View"));

const AdminList = React.lazy(() => import("./AdminList"));
const AdminAdd = React.lazy(() => import("./AdminList/Pages/Add"));
const AdminEdit = React.lazy(() => import("./AdminList/Pages/Edit"));
const AdminView = React.lazy(() => import("./AdminList/Pages/View"));

const DoctorList = React.lazy(() => import("./DoctorList"));
const DoctorAdd = React.lazy(() => import("./DoctorList/Pages/Add"));
const DoctorEdit = React.lazy(() => import("./DoctorList/Pages/Edit"));
const DoctorView = React.lazy(() => import("./DoctorList/Pages/View"));

const CareGiverList = React.lazy(() => import("./CaregiverList"));
const CaregiverAdd = React.lazy(() => import("./CaregiverList/Pages/Add"));
const CaregiverEdit = React.lazy(() => import("./CaregiverList/Pages/Edit"));
const CaregiverView = React.lazy(() => import("./CaregiverList/Pages/View"));

const ChildList = React.lazy(() => import("./ChildList"));
const ChildEdit = React.lazy(() => import("./ChildList/Pages/Edit"));
const ChildView = React.lazy(() => import("./ChildList/Pages/View"));
const ChildImmunization = React.lazy(() =>
  import("./ChildList/Pages/ChildImmunization/ChildImmunization")
);
const AddObservation = React.lazy(() =>
  import("./ChildList/Pages/ChildImmunization/Pages/AddObservation")
);
const EditObservation = React.lazy(() =>
  import("./ChildList/Pages/ChildImmunization/Pages/EditObservation")
);
const AddPrescription = React.lazy(() =>
  import("./ChildList/Pages/ChildImmunization/Pages/AddPrescrription")
);
const EditPrescription = React.lazy(() =>
  import("./ChildList/Pages/ChildImmunization/Pages/EditPresciption")
);
const PatientList = React.lazy(() => import("./PatientList"));
const PatientView = React.lazy(() => import("./PatientList/Pages/View"));

const LearningCentre = React.lazy(() => import("./LearningCentre"));
const VideoAdd = React.lazy(() => import("./LearningCentre/Pages/Add"));
const VideoView = React.lazy(() => import("./LearningCentre/Pages/View"));

const Schedule = React.lazy(() => import("./Schedule"));
const ScheduleAdd = React.lazy(() => import("./Schedule/Pages/Add"));
const ScheduleEdit = React.lazy(() => import("./Schedule/Pages/Edit"));
const LoggingActivityList = React.lazy(() => import("./LoggingActivityList"));
const DhisConfiguration = React.lazy(() => import("./DhisConfiguration"));
const DhisRequestList = React.lazy(() => import("./DhisRequests"));
const DhisLogs = React.lazy(() => import("./DhisLogs"));
const TallyValidation = React.lazy(() => import("./TallyValidation"));
const DhisUpload = React.lazy(() => import("./Dhis2Upload"));
const DhisUpload2 = React.lazy(() => import("./DhisRequests/RequestView"));
const RolesAndPermissions = React.lazy(() =>
  import("./RolesAndPermissions/index.js")
);

const WEB_PAGES = {
  //Dashboards
  MAIN_DASHBOARD: Dashboard,
  IMMUNIZATION_DASHBOARD: ImmunizationDashboard,
  ANC_DASHBOARD: AncDashboard,
  ANC_SERVICES: AncSevices,

  //Clinic List
  CLINIC_LIST: ClinicList,
  CLINIC_ADD: ClinicAdd,
  CLINIC_EDIT: ClinicEdit,
  CLINIC_VIEW: ClinicView,

  //Admin List
  ADMIN_LIST: AdminList,
  ADMIN_ADD: AdminAdd,
  ADMIN_EDIT: AdminEdit,
  ADMIN_VIEW: AdminView,

  //Doctor List
  DOCTOR_LIST: DoctorList,
  DOCTOR_ADD: DoctorAdd,
  DOCTOR_EDIT: DoctorEdit,
  DOCTOR_VIEW: DoctorView,

  // CareGiver
  CARE_GIVER_LIST: CareGiverList,
  CARE_GIVER_ADD: CaregiverAdd,
  CARE_GIVER_EDIT: CaregiverEdit,
  CARE_GIVER_VIEW: CaregiverView,

  //Child List
  CHILD_LIST: ChildList,
  CHILD_EDIT: ChildEdit,
  CHILD_VIEW: ChildView,
  CHILD_IMMUNIZATION: ChildImmunization,
  ADD_OBSERVATION: AddObservation,
  ADD_PRESCRIPTION: AddPrescription,
  EDIT_OBSERVATION: EditObservation,
  EDIT_PRESCRIPTION: EditPrescription,
  //Patient List
  PATIENT_LIST: PatientList,
  PATIENT_VIEW: PatientView,

  // Others
  LEARNING_CENTRE: LearningCentre,
  LEARNING_CENTRE_VIDEO_ADD: VideoAdd,
  LEARNING_CENTRE_VIDEO_VIEW: VideoView,

  SCHEDULE: Schedule,
  SCHEDULE_ADD: ScheduleAdd,
  SCHEDULE_EDIT: ScheduleEdit,
  LOGGING_ACTIVITY: LoggingActivityList,
  //Roles And Permissions
  ROLES_AND_PERMISSIONS: RolesAndPermissions,

  //Dhis Configuration
  DHIS_CONFIGURATION: DhisConfiguration,
  DHIS_UPLOAD: DhisUpload,
  DHIS_UPLOAD_ID: DhisUpload2,
  DHIS_REQUESTS: DhisRequestList,
  DHIS_LOGS: DhisLogs,
  TALLY_VALIDATION: TallyValidation,
  DHIS2_CONFIGURATION: DHIS2Configuration,
};

export { WEB_PAGES };

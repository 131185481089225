import { DHIS2_DATASET_SETUP_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addDataSetSetupLoading: false,
  addDataSetSetupSuccess: false,
  addDataSetSetupFailure: false,
  addDataSetSetupError: null,
  addDataSetSetup: {},

  updateDataSetSetupLoading: false,
  updateDataSetSetupSuccess: false,
  updateDataSetSetupFailure: false,
  updateDataSetSetupError: null,
  updateDataSetSetup: {},

  deleteDataSetSetupLoading: false,
  deleteDataSetSetupSuccess: false,
  deleteDataSetSetupFailure: false,
  deleteDataSetSetupError: null,
  deleteDataSetSetup: {},

  getDataSetSetupListLoading: false,
  getDataSetSetupListSuccess: false,
  getDataSetSetupListFailure: false,
  getDataSetSetupListError: null,
  dataSetSetupList: [],

  getDatabaseLabelListLoading: false,
  getDatabaseLabelListSuccess: false,
  getDatabaseLabelListFailure: false,
  getDatabaseLabelListError: null,
  databaseLabelList: [],

  CheckDHISPushRequestLoading: false,
  CheckDHISPushRequestSuccess: false,
  CheckDHISPushRequestFailure: false,
  CheckDHISPushRequestError: null,
  CheckDHISPushRequestData: [],
};

export const dhisDataSetSetupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_REQUEST:
      return {
        ...state,
        addDataSetSetupLoading: true,
        addDataSetSetupSuccess: false,
        addDataSetSetupFailure: false,
        addDataSetSetupError: null,
        addDataSetSetup: {},
      };
    case DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_FAILURE:
      return {
        ...state,
        addDataSetSetupLoading: false,
        addDataSetSetupSuccess: false,
        addDataSetSetupFailure: true,
        addDataSetSetupError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.ADD_DATASET_SETUP_SUCCESS:
      let updatedDatasetSetupConfiguation = [
        ...state.dataSetSetupList,
        ...action.payload.data,
      ];
      return {
        ...state,
        addDataSetSetupLoading: false,
        addDataSetSetupSuccess: true,
        addDataSetSetupFailure: false,
        addDataSetSetupError: null,
        addDataSetSetup: action.payload,
        dataSetSetupList: updatedDatasetSetupConfiguation
      };
    case DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_REQUEST:
      return {
        ...state,
        updateDataSetSetupLoading: true,
        updateDataSetSetupSuccess: false,
        updateDataSetSetupFailure: false,
        updateDataSetSetupError: null,
        updateDataSetSetup: {},
      };
    case DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_FAILURE:
      return {
        ...state,
        updateDataSetSetupLoading: false,
        updateDataSetSetupSuccess: false,
        updateDataSetSetupFailure: true,
        updateDataSetSetupError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.UPDATE_DATASET_SETUP_SUCCESS:
      let updatedUpdateDatasetSetupConfiguation = [...state.dataSetSetupList];
      let findIndex = updatedUpdateDatasetSetupConfiguation.findIndex(
        (item) => item.id === action.payload.id
      );
      updatedUpdateDatasetSetupConfiguation[findIndex] = action.payload;
      return {
        ...state,
        updateDataSetSetupLoading: false,
        updateDataSetSetupSuccess: true,
        updateDataSetSetupFailure: false,
        updateDataSetSetupError: null,
        updateDataSetSetup: action.payload,
        dataSetSetupList: updatedUpdateDatasetSetupConfiguation,
      };
    case DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_REQUEST:
      return {
        ...state,
        deleteDataSetSetupLoading: true,
        deleteDataSetSetupSuccess: false,
        deleteDataSetSetupFailure: false,
        deleteDataSetSetupError: null,
        deleteDataSetSetup: {},
      };
    case DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_FAILURE:
      return {
        ...state,
        deleteDataSetSetupLoading: false,
        deleteDataSetSetupSuccess: false,
        deleteDataSetSetupFailure: true,
        deleteDataSetSetupError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.DELETE_DATASET_SETUP_SUCCESS:
      let deleteDatasetSetupConfiguration = state.dataSetSetupList.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        deleteDataSetSetupLoading: false,
        deleteDataSetSetupSuccess: true,
        deleteDataSetSetupFailure: false,
        deleteDataSetSetupError: null,
        deleteDataSetSetup: action.payload,
        dataSetSetupList: deleteDatasetSetupConfiguration,
      };
    case DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_REQUEST:
      return {
        ...state,
        getDataSetSetupListLoading: true,
        getDataSetSetupListSuccess: false,
        getDataSetSetupListFailure: false,
        getDataSetSetupListError: null,
        dataSetSetupList: [],
      };
    case DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_FAILURE:
      return {
        ...state,
        getDataSetSetupListLoading: false,
        getDataSetSetupListSuccess: false,
        getDataSetSetupListFailure: true,
        getDataSetSetupListError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.GET_DATASET_SETUP_LIST_SUCCESS:
      return {
        ...state,
        getDataSetSetupListLoading: false,
        getDataSetSetupListSuccess: true,
        getDataSetSetupListFailure: false,
        getDataSetSetupListError: null,
        dataSetSetupList: action.payload,
        dataSetupCount: action.info.count

      };

    case DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_REQUEST:
      return {
        ...state,
        getDatabaseLabelListLoading: true,
        getDatabaseLabelListSuccess: false,
        getDatabaseLabelListFailure: false,
        getDatabaseLabelListError: null,
        databaseLabelList: [],
      };
    case DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_FAILURE:
      return {
        ...state,
        getDatabaseLabelListLoading: false,
        getDatabaseLabelListSuccess: false,
        getDatabaseLabelListFailure: true,
        getDatabaseLabelListError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.GET_DATABASE_LABEL_LIST_SUCCESS:
      return {
        ...state,
        getDatabaseLabelListLoading: false,
        getDatabaseLabelListSuccess: true,
        getDatabaseLabelListFailure: false,
        getDatabaseLabelListError: null,
        databaseLabelList: action.payload,
      };

    case DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_REQUEST:
      return {
        ...state,
        CheckDHISPushRequestLoading: true,
        CheckDHISPushRequestSuccess: false,
        CheckDHISPushRequestFailure: false,
        CheckDHISPushRequestError: null,
        CheckDHISPushRequestData: [],
      };
    case DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_FAILURE:
      return {
        ...state,
        CheckDHISPushRequestLoading: false,
        CheckDHISPushRequestSuccess: false,
        CheckDHISPushRequestFailure: true,
        CheckDHISPushRequestError: action.payload,
      };
    case DHIS2_DATASET_SETUP_REQUEST.CHECK_DHIS_PUSH_SUCCESS:
      return {
        ...state,
        CheckDHISPushRequestLoading: false,
        CheckDHISPushRequestSuccess: true,
        CheckDHISPushRequestFailure: false,
        CheckDHISPushRequestError: null,
        CheckDHISPushRequestData: action.payload,
      };

    default:
      return state;
  }
};

import { ANC_DASHBOARD } from "../actions/utilities";

const INITIAL_STATE = {
  getAncStatsLoading: false,
  getAncStatsSuccess: false,
  getAncStatsFailure: false,
  getAncStatsError: null,
  ancDashboardStats: [],

  getVaccinationChartLoading: false,
  getVaccinationChartSuccess: false,
  getVaccinationChartFailure: false,
  getVaccinationChartError: null,
  vaccinationChartData: [],

  getCompletedVaccinationLoading: false,
  getCompletedVaccinationSuccess: false,
  getCompletedVaccinationFailure: false,
  getCompletedVaccinationError: null,
  completedVaccinationData: [],
  completedVaccinationCount: 0,

  getIncompletedVaccinationLoading: false,
  getIncompletedVaccinationSuccess: false,
  getIncompletedVaccinationFailure: false,
  getIncompletedVaccinationError: null,
  incompletedVaccinationData: [],
  incompletedVaccinationCount: 0,

  getMissedVaccinationLoading: false,
  getMissedVaccinationSuccess: false,
  getMissedVaccinationFailure: false,
  getMissedVaccinationError: null,
  missedVaccinationData: [],
  missedVaccinationCount: 0,

  getMotherVaccinationLoading: false,
  getMotherVaccinationSuccess: false,
  getMotherVaccinationFailure: false,
  getMotherVaccinationError: null,
  motherVaccinationData: [],
  motherVaccinationCount: 0,

  getMotherAncDetailsLoading: false,
  getMotherAncDetailsSuccess: false,
  getMotherAncDetailsFailure: false,
  getMotherAncDetailsError: null,
  motherAncDetailsData: [],

  exportAncStatsExcelLoading: false,
  exportAncStatsExcelSuccess: false,
  exportAncStatsExcelFailure: false,
  exportAncStatsExcelError: null,
  exportAncStatsExcelData: null,

  exportMissedVaccinationLoading: false,
  exportMissedVaccinationSuccess: false,
  exportMissedVaccinationFailure: false,
  exportMissedVaccinationError: null,
  exportMissedVaccinationData: null,

  exportMotherVaccinationLoading: false,
  exportMotherVaccinationSuccess: false,
  exportMotherVaccinationFailure: false,
  exportMotherVaccinationError: null,
  exportMotherVaccinationData: null,
};

export const ancDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANC_DASHBOARD.GET_ANC_STATS_REQUEST:
      return {
        ...state,
        getAncStatsLoading: true,
        getAncStatsSuccess: false,
        getAncStatsFailure: false,
        getAncStatsError: null,
      };
    case ANC_DASHBOARD.GET_ANC_STATS_FAILURE:
      return {
        ...state,
        getAncStatsLoading: false,
        getAncStatsSuccess: false,
        getAncStatsFailure: true,
        getAncStatsError: action.payload,
      };
    case ANC_DASHBOARD.GET_ANC_STATS_SUCCESS:
      return {
        ...state,
        getAncStatsLoading: false,
        getAncStatsSuccess: true,
        getAncStatsFailure: false,
        getAncStatsError: null,
        ancDashboardStats: action.payload,
      };
    case ANC_DASHBOARD.GET_VACCINATION_CHART_REQUEST:
      return {
        ...state,
        getVaccinationChartLoading: true,
        getVaccinationChartSuccess: false,
        getVaccinationChartFailure: false,
        getVaccinationChartError: null,
        vaccinationChartData: [],
      };
    case ANC_DASHBOARD.GET_VACCINATION_CHART_FAILURE:
      return {
        ...state,
        getVaccinationChartLoading: false,
        getVaccinationChartSuccess: false,
        getVaccinationChartFailure: true,
        getVaccinationChartError: action.payload,
      };
    case ANC_DASHBOARD.GET_VACCINATION_CHART_SUCCESS:
      return {
        ...state,
        getVaccinationChartLoading: false,
        getVaccinationChartSuccess: true,
        getVaccinationChartFailure: false,
        getVaccinationChartError: null,
        vaccinationChartData: action.payload,
      };

    case ANC_DASHBOARD.GET_COMPLETED_VACCINATION_REQUEST:
      return {
        ...state,
        getCompletedVaccinationLoading: true,
        getCompletedVaccinationSuccess: false,
        getCompletedVaccinationFailure: false,
        getCompletedVaccinationError: null,
      };
    case ANC_DASHBOARD.GET_COMPLETED_VACCINATION_FAILURE:
      return {
        ...state,
        getCompletedVaccinationLoading: false,
        getCompletedVaccinationSuccess: false,
        getCompletedVaccinationFailure: true,
        getCompletedVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.GET_COMPLETED_VACCINATION_SUCCESS:
      return {
        ...state,
        getCompletedVaccinationLoading: false,
        getCompletedVaccinationSuccess: true,
        getCompletedVaccinationFailure: false,
        getCompletedVaccinationError: null,
        completedVaccinationData: action.payload,
        completedVaccinationCount: action.info.count,
      };
    case ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_REQUEST:
      return {
        ...state,
        getIncompletedVaccinationLoading: true,
        getIncompletedVaccinationSuccess: false,
        getIncompletedVaccinationFailure: false,
        getIncompletedVaccinationError: null,
      };
    case ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_FAILURE:
      return {
        ...state,
        getIncompletedVaccinationLoading: false,
        getIncompletedVaccinationSuccess: false,
        getIncompletedVaccinationFailure: true,
        getIncompletedVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.GET_INCOMPLETED_VACCINATION_SUCCESS:
      return {
        ...state,
        getIncompletedVaccinationLoading: false,
        getIncompletedVaccinationSuccess: true,
        getIncompletedVaccinationFailure: false,
        getIncompletedVaccinationError: null,
        incompletedVaccinationData: action.payload,
        incompletedVaccinationCount: action.info.count,
      };
    case ANC_DASHBOARD.GET_MISSED_VACCINATION_REQUEST:
      return {
        ...state,
        getMissedVaccinationLoading: true,
        getMissedVaccinationSuccess: false,
        getMissedVaccinationFailure: false,
        getMissedVaccinationError: null,
      };
    case ANC_DASHBOARD.GET_MISSED_VACCINATION_FAILURE:
      return {
        ...state,
        getMissedVaccinationLoading: false,
        getMissedVaccinationSuccess: false,
        getMissedVaccinationFailure: true,
        getMissedVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.GET_MISSED_VACCINATION_SUCCESS:
      return {
        ...state,
        getMissedVaccinationLoading: false,
        getMissedVaccinationSuccess: true,
        getMissedVaccinationFailure: false,
        getMissedVaccinationError: null,
        missedVaccinationData: action.payload,
        missedVaccinationCount: action.info.count,
      };

    case ANC_DASHBOARD.GET_MOTHER_VACCINATION_REQUEST:
      return {
        ...state,
        getMotherVaccinationLoading: true,
        getMotherVaccinationSuccess: false,
        getMotherVaccinationFailure: false,
        getMotherVaccinationError: null,
      };
    case ANC_DASHBOARD.GET_MOTHER_VACCINATION_FAILURE:
      return {
        ...state,
        getMotherVaccinationLoading: false,
        getMotherVaccinationSuccess: false,
        getMotherVaccinationFailure: true,
        getMotherVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.GET_MOTHER_VACCINATION_SUCCESS:
      return {
        ...state,
        getMotherVaccinationLoading: false,
        getMotherVaccinationSuccess: true,
        getMotherVaccinationFailure: false,
        getMotherVaccinationError: null,
        motherVaccinationData: action.payload,
        motherVaccinationCount: action.info.count,
      };
    case ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_REQUEST:
      return {
        ...state,
        getMotherAncDetailsLoading: true,
        getMotherAncDetailsSuccess: false,
        getMotherAncDetailsFailure: false,
        getMotherAncDetailsError: null,
      };
    case ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_FAILURE:
      return {
        ...state,
        getMotherAncDetailsLoading: false,
        getMotherAncDetailsSuccess: false,
        getMotherAncDetailsFailure: true,
        getMotherAncDetailsError: action.payload,
      };
    case ANC_DASHBOARD.GET_MOTHER_ANC_DETAILS_SUCCESS:
      return {
        ...state,
        getMotherAncDetailsLoading: false,
        getMotherAncDetailsSuccess: true,
        getMotherAncDetailsFailure: false,
        getMotherAncDetailsError: null,
        motherAncDetailsData: action.payload,
      };
    case ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_REQUEST:
      return {
        ...state,
        exportAncStatsExcelLoading: true,
        exportAncStatsExcelSuccess: false,
        exportAncStatsExcelFailure: false,
        exportAncStatsExcelError: null,
      };
    case ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_FAILURE:
      return {
        ...state,
        exportAncStatsExcelLoading: false,
        exportAncStatsExcelSuccess: false,
        exportAncStatsExcelFailure: true,
        exportAncStatsExcelError: action.payload,
      };
    case ANC_DASHBOARD.EXPORT_ANC_STATS_EXCEL_SUCCESS:
      return {
        ...state,
        exportAncStatsExcelLoading: false,
        exportAncStatsExcelSuccess: true,
        exportAncStatsExcelFailure: false,
        exportAncStatsExcelError: null,
        exportAncStatsExcelData: action.payload,
      };

    case ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_REQUEST:
      return {
        ...state,
        exportMissedVaccinationLoading: true,
        exportMissedVaccinationSuccess: false,
        exportMissedVaccinationFailure: false,
        exportMissedVaccinationError: null,
      };
    case ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_FAILURE:
      return {
        ...state,
        exportMissedVaccinationLoading: false,
        exportMissedVaccinationSuccess: false,
        exportMissedVaccinationFailure: true,
        exportMissedVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.EXPORT_MISSED_VACCINATION_SUCCESS:
      return {
        ...state,
        exportMissedVaccinationLoading: false,
        exportMissedVaccinationSuccess: true,
        exportMissedVaccinationFailure: false,
        exportMissedVaccinationError: null,
        exportMissedVaccinationData: action.payload,
      };

    case ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_REQUEST:
      return {
        ...state,
        exportMotherVaccinationLoading: true,
        exportMotherVaccinationSuccess: false,
        exportMotherVaccinationFailure: false,
        exportMotherVaccinationError: null,
      };
    case ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_FAILURE:
      return {
        ...state,
        exportMotherVaccinationLoading: false,
        exportMotherVaccinationSuccess: false,
        exportMotherVaccinationFailure: true,
        exportMotherVaccinationError: action.payload,
      };
    case ANC_DASHBOARD.EXPORT_MOTHER_VACCINATION_SUCCESS:
      return {
        ...state,
        exportMotherVaccinationLoading: false,
        exportMotherVaccinationSuccess: true,
        exportMotherVaccinationFailure: false,
        exportMotherVaccinationError: null,
        exportMotherVaccinationData: action.payload,
      };

    default:
      return state;
  }
};

import { DHIS2_FACILITY_CONFIGURATION_REQUEST } from "../actions/utilities";

const INITIAL_STATE = {
  addFacilityConfigurationLoading: false,
  addFacilityConfigurationSuccess: false,
  addFacilityConfigurationFailure: false,
  addFacilityConfigurationError: null,
  addFacilityConfiguration: {},

  updateFacilityConfigurationLoading: false,
  updateFacilityConfigurationSuccess: false,
  updateFacilityConfigurationFailure: false,
  updateFacilityConfigurationError: null,
  updateFacilityConfiguration: {},

  deleteFacilityConfigurationLoading: false,
  deleteFacilityConfigurationSuccess: false,
  deleteFacilityConfigurationFailure: false,
  deleteFacilityConfigurationError: null,
  deleteFacilityConfiguration: {},

  getFacilityConfigurationMappingListLoading: false,
  getFacilityConfigurationMappingListSuccess: false,
  getFacilityConfigurationMappingListFailure: false,
  getFacilityConfigurationMappingListError: null,
  facilityConfigurationMappingList: [],
  facilityConfigurationMappingListCount: 0,
  
  
  addLocalFacilityConfigurationDataLoading: false,
  addLocalFacilityConfigurationDataSuccess: false,
  addLocalFacilityConfigurationDataFailure: false,
  addLocalFacilityConfigurationDataError: null,

  removeLocalFacilityConfigurationDataLoading: false,
  removeLocalFacilityConfigurationDataSuccess: false,
  removeLocalFacilityConfigurationDataFailure: false,
  removeLocalFacilityConfigurationDataError: null,

  getDHISFacilitiesListingLoading: false,
  getDHISFacilitiesListingSuccess: false,
  getDHISFacilitiesListingFailure: false,
  getDHISFacilitiesListingError: null,
  getDHISFacilitiesListingData: [],

  deleteDHISFacilityLoading: false,
  deleteDHISFacilitySuccess: false,
  deleteDHISFacilityFailure: false,
  deleteDHISFacilityError: null,
  deleteDHISFacilityData: [],

  updateDHISFacilityLoading: false,
  updateDHISFacilitySuccess: false,
  updateDHISFacilityFailure: false,
  updateDHISFacilityError: null,
  updateDHISFacilityData: [],

  getByIdDHISFacilityLoading: false,
  getByIdDHISFacilitySuccess: false,
  getByIdDHISFacilityFailure: false,
  getByIdDHISFacilityError: null,
  getByIdDHISFacilityData: [],

  addDHISFacilityLoading: false,
  addDHISFacilitySuccess: false,
  addDHISFacilityFailure: false,
  addDHISFacilityError: null,
  addDHISFacilityData: [],
};

export const dhisFacilityConfigurationReducer = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_REQUEST:
      return {
        ...state,
        addDHISFacilityLoading: true,
        addDHISFacilitySuccess: false,
        addDHISFacilityFailure: false,
        addDHISFacilityError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_FAILURE:
      return {
        ...state,
        addDHISFacilityLoading: false,
        addDHISFacilitySuccess: false,
        addDHISFacilityFailure: true,
        addDHISFacilityError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_DHIS2_FACILITY_SUCCESS:
      return {
        ...state,
        addDHISFacilityLoading: false,
        addDHISFacilitySuccess: true,
        addDHISFacilityFailure: false,
        addDHISFacilityError: null,
        addDHISFacilityData: action.payload,
      };

    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_REQUEST:
      return {
        ...state,
        getByIdDHISFacilityLoading: true,
        getByIdDHISFacilitySuccess: false,
        getByIdDHISFacilityFailure: false,
        getByIdDHISFacilityError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_FAILURE:
      return {
        ...state,
        getByIdDHISFacilityLoading: false,
        getByIdDHISFacilitySuccess: false,
        getByIdDHISFacilityFailure: true,
        getByIdDHISFacilityError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_BY_ID_DHIS2_FACILITY_SUCCESS:
      return {
        ...state,
        getByIdDHISFacilityLoading: false,
        getByIdDHISFacilitySuccess: true,
        getByIdDHISFacilityFailure: false,
        getByIdDHISFacilityError: null,
        getByIdDHISFacilityData: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_REQUEST:
      return {
        ...state,
        updateDHISFacilityLoading: true,
        updateDHISFacilitySuccess: false,
        updateDHISFacilityFailure: false,
        updateDHISFacilityError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_FAILURE:
      return {
        ...state,
        updateDHISFacilityLoading: false,
        updateDHISFacilitySuccess: false,
        updateDHISFacilityFailure: true,
        updateDHISFacilityError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_DHIS2_FACILITY_SUCCESS:
      return {
        ...state,
        updateDHISFacilityLoading: false,
        updateDHISFacilitySuccess: true,
        updateDHISFacilityFailure: false,
        updateDHISFacilityError: null,
        updateDHISFacilityData: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_REQUEST:
      return {
        ...state,
        deleteDHISFacilityLoading: true,
        deleteDHISFacilitySuccess: false,
        deleteDHISFacilityFailure: false,
        deleteDHISFacilityError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_FAILURE:
      return {
        ...state,
        deleteDHISFacilityLoading: false,
        deleteDHISFacilitySuccess: false,
        deleteDHISFacilityFailure: true,
        deleteDHISFacilityError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_DHIS2_FACILITY_SUCCESS:
      return {
        ...state,
        deleteDHISFacilityLoading: false,
        deleteDHISFacilitySuccess: true,
        deleteDHISFacilityFailure: false,
        deleteDHISFacilityError: null,
        deleteDHISFacilityData: action.payload,
      };

    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_REQUEST:
      return {
        ...state,
        getDHISFacilitiesListingLoading: true,
        getDHISFacilitiesListingSuccess: false,
        getDHISFacilitiesListingFailure: false,
        getDHISFacilitiesListingError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_FAILURE:
      return {
        ...state,
        getDHISFacilitiesListingLoading: false,
        getDHISFacilitiesListingSuccess: false,
        getDHISFacilitiesListingFailure: true,
        getDHISFacilitiesListingError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_ALL_DHIS2_FACILITY_SUCCESS:
      return {
        ...state,
        getDHISFacilitiesListingLoading: false,
        getDHISFacilitiesListingSuccess: true,
        getDHISFacilitiesListingFailure: false,
        getDHISFacilitiesListingError: null,
        getDHISFacilitiesListingData: action.payload,
      };


    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_REQUEST:
      return {
        ...state,
        addFacilityConfigurationLoading: true,
        addFacilityConfigurationSuccess: false,
        addFacilityConfigurationFailure: false,
        addFacilityConfigurationError: null,
        addFacilityConfiguration: {},
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_FAILURE:
      return {
        ...state,
        addFacilityConfigurationLoading: false,
        addFacilityConfigurationSuccess: false,
        addFacilityConfigurationFailure: true,
        addFacilityConfigurationError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_FACILITY_CONFIGURATION_SUCCESS:
      let updatedFacilityConfiguation = [
        ...state.facilityConfigurationMappingList,
        ...action.payload.data,
      ];
      return {
        ...state,
        addFacilityConfigurationLoading: false,
        addFacilityConfigurationSuccess: true,
        addFacilityConfigurationFailure: false,
        addFacilityConfigurationError: null,
        addFacilityConfiguration: action.payload,
        facilityConfigurationMappingList: updatedFacilityConfiguation,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_REQUEST:
      return {
        ...state,
        updateFacilityConfigurationLoading: true,
        updateFacilityConfigurationSuccess: false,
        updateFacilityConfigurationFailure: false,
        updateFacilityConfigurationError: null,
        updateFacilityConfiguration: {},
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_FAILURE:
      return {
        ...state,
        updateFacilityConfigurationLoading: false,
        updateFacilityConfigurationSuccess: false,
        updateFacilityConfigurationFailure: true,
        updateFacilityConfigurationError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.UPDATE_FACILITY_CONFIGURATION_SUCCESS:
      let updatedUpdateFacilityConfiguation = [
        ...state.facilityConfigurationMappingList,
      ];
      let findIndex = updatedUpdateFacilityConfiguation.findIndex(
        (item) => item.id === action.payload.id
      );
      updatedUpdateFacilityConfiguation[findIndex] = action.payload;
      return {
        ...state,
        updateFacilityConfigurationLoading: false,
        updateFacilityConfigurationSuccess: true,
        updateFacilityConfigurationFailure: false,
        updateFacilityConfigurationError: null,
        updateFacilityConfiguration: action.payload,
        facilityConfigurationMappingList: updatedUpdateFacilityConfiguation,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_REQUEST:
      return {
        ...state,
        deleteFacilityConfigurationLoading: true,
        deleteFacilityConfigurationSuccess: false,
        deleteFacilityConfigurationFailure: false,
        deleteFacilityConfigurationError: null,
        deleteFacilityConfiguration: {},
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_FAILURE:
      return {
        ...state,
        deleteFacilityConfigurationLoading: false,
        deleteFacilityConfigurationSuccess: false,
        deleteFacilityConfigurationFailure: true,
        deleteFacilityConfigurationError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.DELETE_FACILITY_CONFIGURATION_SUCCESS:
      let deleteFacilityConfiguration = state.facilityConfigurationMappingList.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        deleteFacilityConfigurationLoading: false,
        deleteFacilityConfigurationSuccess: true,
        deleteFacilityConfigurationFailure: false,
        deleteFacilityConfigurationError: null,
        deleteFacilityConfiguration: action.payload,
        facilityConfigurationMappingList: deleteFacilityConfiguration,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_REQUEST:
      return {
        ...state,
        getFacilityConfigurationMappingListLoading: true,
        getFacilityConfigurationMappingListSuccess: false,
        getFacilityConfigurationMappingListFailure: false,
        getFacilityConfigurationMappingListError: null,
        facilityConfigurationMappingList: [],
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_FAILURE:
      return {
        ...state,
        getFacilityConfigurationMappingListLoading: false,
        getFacilityConfigurationMappingListSuccess: false,
        getFacilityConfigurationMappingListFailure: true,
        getFacilityConfigurationMappingListError: action.payload,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.GET_FACILITY_CONFIGURATION_MAPPING_LIST_SUCCESS:
      return {
        ...state,
        getFacilityConfigurationMappingListLoading: false,
        getFacilityConfigurationMappingListSuccess: true,
        getFacilityConfigurationMappingListFailure: false,
        getFacilityConfigurationMappingListError: null,
        facilityConfigurationMappingList: action.payload,
        facilityConfigurationMappingListCount: action.info.count,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.ADD_LOCAL_FACILITY_CONFIGURATION_DATA:
      let updatedConfigurationData = [
        ...state.facilityConfigurationMappingList,
      ];
      updatedConfigurationData.push(action.payload);
      return {
        ...state,
        addLocalFacilityConfigurationDataLoading: false,
        addLocalFacilityConfigurationDataSuccess: true,
        addLocalFacilityConfigurationDataFailure: false,
        addLocalFacilityConfigurationDataError: null,
        facilityConfigurationMappingList: updatedConfigurationData,
      };
    case DHIS2_FACILITY_CONFIGURATION_REQUEST.REMOVE_LOCAL_FACILITY_CONFIGURATION_DATA:                                                                                                       
      updatedConfigurationData.filter(action.payload);
      return {
        ...state,
        removeLocalFacilityConfigurationDataLoading: false,
        removeLocalFacilityConfigurationDataSuccess: true,
        removeLocalFacilityConfigurationDataFailure: false,
        removeLocalFacilityConfigurationDataError: null,
        facilityConfigurationMappingList: updatedConfigurationData,
      };
    default:
      return state;
  }
};

import { addLocationApi, getAllLocationApi, getLocationApi } from "../api/locationApi";
import { failure, LOCATIONS, request, success } from "./utilities";



export function getAllLocationAction(data) {
    return (dispatch) => {
      dispatch(request(LOCATIONS.GET_ALL_LOCATIONS_REQUEST));
      getAllLocationApi(data).then(
        (response) => {
          if (response.data.success === true) {
            dispatch(
              success(LOCATIONS.GET_ALL_LOCATIONS_SUCCESS, response.data.data, {
              })
            );
          } else {
            dispatch(
              failure(LOCATIONS.GET_ALL_LOCATIONS_FAILURE, response.data.message)
            );
          }
        },
        (error) => {
          dispatch(failure(LOCATIONS.GET_ALL_LOCATIONS_FAILURE, error.message));
        }
      );
    };
}

export function getLocationAction(data) {
  return (dispatch) => {
    dispatch(request(LOCATIONS.GET_LOCATIONS_REQUEST));
    getLocationApi(data).then(
      (response) => {
        if (response.data.success === true) {
          dispatch(
            success(LOCATIONS.GET_LOCATIONS_SUCCESS, response.data.data, {
            })
          );
        } else {
          dispatch(
            failure(LOCATIONS.GET_LOCATIONS_FAILURE, response.data.message)
          );
        }
      },
      (error) => {
        dispatch(failure(LOCATIONS.GET_LOCATIONS_FAILURE, error.message));
      }
    );
  };
}

export function addLocationAction(data) {
    return (dispatch) => {
      dispatch(request(LOCATIONS.ADD_LOCATIONS_REQUEST));
      addLocationApi(data).then(
        (response) => {
          if (response.data.success === true) {
            dispatch(
              success(LOCATIONS.ADD_LOCATIONS_SUCCESS, response.data.data, {
              })
            );
          } else {
            dispatch(
              failure(LOCATIONS.ADD_LOCATIONS_FAILURE, response.data.message)
            );
          }
        },
        (error) => {
          dispatch(failure(LOCATIONS.ADD_LOCATIONS_FAILURE, error.message));
        }
      );
    };
}
  